import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice";
import { LogoProvider } from "./hooks/logo-context"; // Import the LogoProvider
import { UrlProvider} from "./helpers/url-context";
import Auth0ProviderWithHistory from './helpers/Auth0ProviderWithHistory';
import { AuthProvider } from './helpers/auth-context';
import init from "./data/setup/init.json";
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";

const site = (init.multiBrandSites.length > 1) ? init.multiBrandSites : init.siteName;

const fetchData = () => {
  const payload = {
    q: 'web_items',
    siteName: site,
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  return fetch('https://us-central1-kinetic-object-314621.cloudfunctions.net/webDeals', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      throw error; // Re-throw the error to handle it elsewhere if needed
    });
};

fetchData()
  .then(products => {
    store.dispatch(setProducts(products));
  })
  .catch(error => {
    console.error('Error in data fetching:', error);
  });

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistProvider>
      <Router>
        <Auth0ProviderWithHistory>
          <AuthProvider> {/* Wrap with AuthProvider */}
          <UrlProvider>
            <LogoProvider>
              <App />
            </LogoProvider>
          </UrlProvider>
          </AuthProvider>
        </Auth0ProviderWithHistory>
        </Router>
    </PersistProvider>
  </Provider>
);

